import fetchApiWithAuth from "./fetch";
export type AuthStatus = {
  ozon: {
    status: string;
    login: string;
    expired: string;
  };
  wb: {
    status: string;
    phone: string;
    expired: string;
  };
};
export const getAuthStatus = async () => {
  const result = await fetchApiWithAuth("/api/markerplace-auth/status");
  const data = (await result.json()) as AuthStatus;
  return data;
};
export const setOzonCookieToBrowser = async () => {
  const result = await fetchApiWithAuth("/api/markerplace-auth/set_browser_ozon_cookie", { method: "POST", body: '{}' });
  const data = (await result.json());
  return data;
};

export const AuthOzon = async (props: { login: string; password: string }) => {
  const result = await fetchApiWithAuth("/api/markerplace-auth/ozon", {
    method: "POST",
    body: JSON.stringify(props),
  });
  const data = await result.json();
  return data;
};

export const AuthWB = async (props: { phone: string }) => {
  const result = await fetchApiWithAuth("/api/markerplace-auth/wb", {
    method: "POST",
    body: JSON.stringify(props),
  });
  const data = await result.json();
  return data;
};

export const AuthWBCode = async (props: { code: string }) => {
  const result = await fetchApiWithAuth("/api/markerplace-auth/wb-code", {
    method: "POST",
    body: JSON.stringify(props),
  });
  const data = await result.json();
  return data;
};
